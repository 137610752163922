import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { RoomBedIcon } from "../../../../../../../assets/icons";
import useRoomOptionAPI from "../../../../../../../hooks/api/useRoomOptionAPI";
import styles from "./styles.module.scss";

export default function RoomOptionModal({
  open,
  onClose,
  onSave,
  initialSelectedIds,
  openIndex,
}) {
  const [selected, setSelected] = useState([]);

  const { data } = useRoomOptionAPI({
    enabled: true,
    page: 0,
    room_type_id: openIndex,
  });

  const roomOptions = useMemo(() => {
    return (
      data?.roomOptions?.rows?.map((item) => ({
        label: item?.name,
        value: item?.id,
      })) || []
    );
  }, [data]);

  useEffect(() => {
    if (open) {
      setSelected(initialSelectedIds || []);
    }
  }, [open, initialSelectedIds]);

  const handleToggleOption = (optionId) => {
    setSelected((prev) => {
      if (prev.includes(optionId)) {
        return prev.filter((id) => id !== optionId);
      } else {
        return [...prev, optionId];
      }
    });
  };

  const handleSave = () => {
    onSave(selected);
  };

  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
    >
      <Box className={styles.box}>
        <CloseIcon onClick={handleClose} className={styles.close} />
        <div className={styles.header}>
          <div className={styles.add_user}>
            <RoomBedIcon />
          </div>
          <div className={styles.content}>
            <h3>Гостиничный номер</h3>
            <p>Изминить детали гостиничный номерa</p>
          </div>
        </div>
        <FormGroup className={styles.list}>
          {roomOptions.map((option) => (
            <FormControlLabel
              style={{
                columnGap: "12px",
              }}
              key={option.value}
              control={
                <Checkbox
                  className={styles.checkbox}
                  sx={{
                    // color: pink[800],
                    "&.Mui-checked": {
                      color: "#079455",
                    },
                  }}
                  checked={selected.includes(option.value)}
                  onChange={() => handleToggleOption(option.value)}
                />
              }
              label={option.label}
            />
          ))}
        </FormGroup>
        <div className={styles.footer}>
          <Button className={styles.cancel_button} onClick={handleClose}>
            Cancel
          </Button>
          <Button className={styles.save_button} onClick={handleSave}>
            Save
          </Button>
        </div>
      </Box>
    </Dialog>
  );
}
