import { useState } from "react";
import classNames from "classnames";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import AirlinesIcon from "@mui/icons-material/Airlines";
import HotelIcon from "@mui/icons-material/Hotel";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TypeSpecimenIcon from "@mui/icons-material/TypeSpecimen";
import StyleIcon from "@mui/icons-material/Style";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import PaymentIcon from "@mui/icons-material/Payment";
const items = [
  {
    title: "Airports",
    path: "airports",
    icon: ConnectingAirportsIcon,
  },
  {
    title: "Airlines",
    path: "airlines",
    icon: AirlinesIcon,
  },
  {
    title: "Insurance",
    path: "/static-data/insurance",
    icon: AssignmentIcon,
  },

  {
    title: "GP Table",
    path: "/static-data/payment-method",
    icon: BackupTableIcon,
    children: [
      {
        title: "Payment method",
        path: "/static-data/payment-method",
        icon: PaymentIcon,
      },
      {
        title: "Ticket Merchant",
        path: "/static-data/ticket-merchant",
        icon: AirplaneTicketIcon,
      },
    ],
  },
  {
    title: "Hotels",
    path: "hotels",
    icon: HotelIcon,
    children: [
      {
        title: "Room Types",
        path: "/static-data/hotels/room-type",
        icon: TypeSpecimenIcon,
      },
      {
        title: "Room Options",
        path: "/static-data/hotels/room-option",
        icon: StyleIcon,
      },
    ],
  },
];

const StaticData = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  const handleClick = (item) => {
    if (item.children) {
      setOpen((prev) => !prev);
      navigate(item.path);
    } else {
      navigate(item.path);
    }
  };

  return (
    <div className={styles.static_data}>
      <div className={styles.sidebar}>
        <ul className={styles.list}>
          {items.map((item) => (
            <>
              <li
                key={item.path}
                className={classNames(styles.item, {
                  [styles.active]: pathname.includes(item.path),
                })}
                onClick={() => handleClick(item)}
              >
                <div className={styles.content}>
                  <item.icon /> {item.title}
                </div>
                {item.children && (
                  <span
                    className={classNames(styles.collapse_icon, {
                      [styles.collapsed]: open === item.path,
                    })}
                  >
                    <KeyboardArrowRightIcon
                      className={classNames(styles.icon, {
                        [styles.transform]:
                          open && pathname.includes(item.path),
                      })}
                    />
                  </span>
                )}
              </li>
              {item.children && pathname.includes(item.path) ? (
                <Collapse in={open}>
                  <div className={styles.sublist}>
                    {item.children.map((child) => (
                      <li
                        key={child.path}
                        className={classNames(styles.subitem, {
                          [styles.active]: pathname.includes(child.path),
                        })}
                        onClick={() => {
                          navigate(child.path);
                        }}
                      >
                        <child.icon /> {child.title}
                      </li>
                    ))}
                  </div>
                </Collapse>
              ) : null}
            </>
          ))}
        </ul>
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};

export default StaticData;
