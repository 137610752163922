import React, { useCallback, useState } from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import styles from "./style.module.scss";
import HotelCard from "./components/HotelCard";
import HotelSearch from "./components/HotelSearch";
import { useSelector } from "react-redux";
import { orderSections } from "../..";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import moment from "moment";

const HotelSection = () => {
  const [type, setType] = useState("list");
  const navigate = useNavigate();
  const { orderId: existedOrderId } = useParams();
  const { selectedHotels } = useSelector((store) => store.hotels);
  const { passengers, isConfirmed, isCanceled } = useOutletContext();

  const handleBack = () => {
    navigate(`../${orderSections.FLIGHTS}`);
  };

  console.log("selectedHotels", selectedHotels);

  const onContinue = useCallback(() => {
    // if (selectedHotels?.length === 0) return toast.error("Add a hotel!");

    navigate(`../${orderSections.PAYMENTS}`);
  }, [isConfirmed, selectedHotels]);

  const getRooms = (hotel) => {
    if (!hotel) return [];

    const rooms = hotel.rooms || [];

    const roomCount = rooms.reduce((acc, room) => {
      const { name } = room || {};
      if (!name) return acc;
      acc[name] = (acc[name] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(roomCount).map(([name, count]) => (
      <p key={name}>
        {name} - {count}
      </p>
    ));
  };

  if (!selectedHotels.length || type === "search") {
    return <HotelSearch setType={setType} selectedHotels={selectedHotels} />;
  }
  return (
    <Box className={styles.hotelSection} position="relative">
      <Box className={styles.header}>
        <Typography className={styles.title}>Hotels</Typography>
        <Button
          className={styles.addHotelButton}
          variant="contained"
          size="medium"
          onClick={() => setType("search")}
        >
          Add hotel
        </Button>
      </Box>

      <Grid container spacing={1}>
        {selectedHotels.map((hotel) => (
          <Grid item sm={3} md={3} lg={4} key={hotel.unique_id}>
            <HotelCard
              name={hotel?.label}
              check_in={moment.utc(hotel?.check_in).format("YYYY-MM-DD HH:mm")}
              check_out={moment
                .utc(hotel?.check_out)
                .format("YYYY-MM-DD HH:mm")}
              roomType={getRooms(hotel)}
              adults={hotel?.adult}
              children={hotel?.children}
              infant={hotel?.infant}
              existedOrderId={existedOrderId}
              data={hotel}
            />
          </Grid>
        ))}
      </Grid>
      <Box
        position="absolute"
        left="-17px"
        bottom="-17px"
        width="calc(100% + 34px)"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        bgcolor="white"
        height={56}
        padding="8px"
        style={{
          "box-shadow": " 0px 1px 0px 0px rgba(229, 233, 235, 1) inset",
        }}
      >
        {isConfirmed && (
          <Button variant="outlinedSecondary" onClick={handleBack}>
            Back
          </Button>
        )}
        <Button
          onClick={onContinue}
          style={{ marginLeft: 12 }}
          className={styles.button}
        >
          {isConfirmed ? "Next" : "SAVE & CONTINUE"}
          <ArrowForwardIosOutlinedIcon
            style={{
              fontSize: "12px",
              marginLeft: 12,
            }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default HotelSection;
