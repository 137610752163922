import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useWatch } from "react-hook-form";
import { RoomBedIcon } from "../../../../../../../assets/icons";
import RoomOptionModal from "../RoomOptionModal";
import styles from "./style.module.scss";

export default function RoomSection({ control, setValue }) {
  const rooms = useWatch({
    control,
    name: "rooms",
  });

  const [openIndex, setOpenIndex] = useState(null);

  const handleOpenModal = (index) => {
    setOpenIndex(index);
  };

  const handleCloseModal = () => {
    setOpenIndex(null);
  };

  const handleSaveModal = (selectedOptionIds) => {
    if (openIndex === null) return;
    const newRooms = [...rooms];
    newRooms[openIndex] = {
      ...newRooms[openIndex],
      room_option_ids: selectedOptionIds,
    };
    setValue("rooms", newRooms);
    setOpenIndex(null);
  };
  if (!rooms?.length) return;

  return (
    <Box className={styles.infoCard}>
      <Typography variant="h6" className={styles.title}>
        Additional Room Options
      </Typography>

      <Box className={styles.list}>
        {rooms?.map((item, index) => (
          <Box
            key={index}
            className={styles.room}
            onClick={() => handleOpenModal(item?.id || item?.room_type_id)}
            style={{ cursor: "pointer" }}
          >
            <Box className={styles.icon}>
              <RoomBedIcon />
            </Box>
            <Box className={styles.content}>
              <h5>{item.name || "Untitled room"}</h5>
              <p>1 очень большая двуспальная кровать</p>
            </Box>
          </Box>
        ))}
      </Box>

      {openIndex !== null && (
        <RoomOptionModal
          open={openIndex !== null}
          openIndex={openIndex}
          onClose={handleCloseModal}
          onSave={handleSaveModal}
          initialSelectedIds={rooms[openIndex]?.room_option_ids || []}
        />
      )}
    </Box>
  );
}
