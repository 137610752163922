import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Content from "../../../../components/Content";
import Wrapper from "../../../../components/Wrapper";
import styles from "./style.module.scss";
import useRoomOptionAPI from "../../../../hooks/api/useRoomOptionAPI";

const RoomOptionCreate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { roomOption, createRoomOption, updateRoomOption } = useRoomOptionAPI({
    roomOptionId: id,
  });

  const { control, handleSubmit } = useForm({
    values: { ...(roomOption?.roomOption || {}) },
  });

  const onClick = useCallback((vals) => {
    if (!!id) {
      updateRoomOption.mutate(
        {
          ...vals,
        },
        {
          onSuccess: () => {
            toast.success("Room Option is edited successful!");
            navigate("../");
          },
          onError: () => {
            toast.error("Error in editing Room Option!");
          },
        }
      );
    } else
      createRoomOption.mutate(
        {
          ...vals,
        },
        {
          onSuccess: () => {
            toast.success("Room Option is added successful!");
            navigate("../");
          },
          onError: () => {
            toast.error("Error in adding Room Option!");
          },
        }
      );
  }, []);

  return (
    <form onSubmit={handleSubmit(onClick)}>
      <Wrapper>
        <div>
          <Content
            title={!!id ? "Edit Room option" : "Add Room option"}
            height="80vh"
          >
            <Box width="100%" display="flex" gap="20px">
              <Box width="50%" display="flex" gap="12px" marginTop="24px">
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Name</p>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} required />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Content>
        </div>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            type="submit"
            variant="outlinedSecondary"
            disabled={createRoomOption.isLoading}
            style={{
              width: "120px",
            }}
          >
            {createRoomOption.isLoading ? (
              <CircularProgress size={22} />
            ) : !!id ? (
              "Save"
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Wrapper>
    </form>
  );
};

export default RoomOptionCreate;
