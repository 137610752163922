import { useMutation, useQuery } from "react-query";
import { request } from "../../utils/api";

const useTicketMerchantAPI = (props) => {
  const { data, refetch, isLoading } = useQuery(
    ["GET_TICKET_MERCHANT", props?.page, props?.amount],
    () =>
      request.get("ticket-merchant", {
        params: {
          page: props.page - 1,
          limit: props.amount,
        },
      }),
    { enabled: !!props?.enabled }
  );

  const { data: ticketMerchant } = useQuery(
    ["GET_TICKET_MERCHANT", props?.ticketMerchantId],
    () => request.get("ticket-merchant/" + props?.ticketMerchantId),
    {
      enabled: !!props?.ticketMerchantId,
      onSuccess: props?.handleHotelSuccess,
    }
  );

  const createTicketMerchant = useMutation((data) =>
    request.post("ticket-merchant", data)
  );

  const updateTicketMerchant = useMutation((data) =>
    request.put("ticket-merchant/" + data.id, data)
  );

  const deleteTicketMerchant = useMutation((id) =>
    request.delete("ticket-merchant/" + id)
  );

  return {
    data,
    isLoading,
    refetch,
    ticketMerchant,
    createTicketMerchant,
    updateTicketMerchant,
    deleteTicketMerchant,
  };
};

export default useTicketMerchantAPI;
