import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import galleryStyles from "./style.module.scss";
import { useWatch } from "react-hook-form";

const HotelGallery = ({ control, setOpen }) => {
  const images = useWatch({
    control,
    name: "images",
  });

  const imgs = images?.map((item) => {
    return {
      original: item,
      thumbnail: item,
    };
  });
  return (
    <div className={galleryStyles.galleryContainer} id="hotel-gallery">
      {images && (
        <ImageGallery
          onClick={() => setOpen(true)}
          items={imgs}
          showThumbnails={true}
          showPlayButton={false}
          showFullscreenButton={false}
          additionalClass={galleryStyles.customGallery}
        />
      )}
    </div>
  );
};

export default HotelGallery;
