import { useMutation, useQuery } from "react-query";
import { request } from "../../utils/api";

const useRoomTypeAPI = (props) => {
  const { data, refetch, isLoading } = useQuery(
    ["GET_ROOM_TYPE", props?.page, props?.amount],
    () =>
      request.get("room-type", {
        params: {
          page: props?.page - 1 || undefined,
          limit: props?.amount,
        },
      }),
    { enabled: !!props?.enabled }
  );

  const { data: roomType } = useQuery(
    ["GET_ROOM_TYPE", props?.roomTypeId],
    () => request.get("room-type/" + props?.roomTypeId),
    {
      enabled: !!props?.roomTypeId,
    }
  );

  const createRoomType = useMutation((data) => request.post("room-type", data));

  const updateRoomType = useMutation((data) =>
    request.put("room-type/" + data.id, data)
  );

  const deleteRoomType = useMutation((id) => request.delete("room-type/" + id));

  return {
    data,
    isLoading,
    refetch,
    roomType,
    createRoomType,
    updateRoomType,
    deleteRoomType,
  };
};

export default useRoomTypeAPI;
