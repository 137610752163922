import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, Menu, Popover } from "@mui/material";
import Fade from "@mui/material/Fade";
import { Controller, useForm, useWatch } from "react-hook-form";
import { NotePadHeaderIcon } from "../../../../../../assets/icons";
import styles from "./style.module.scss";
import useNoteAPI from "../../../../../../hooks/api/useNoteAPI";
import { useDebounce } from "use-debounce";
import { useEffect, useState } from "react";
import "./style.scss";

export default function NotePadMenu({
  open,
  onClose,
  anchorEl,
  orderId,
  noteControl,
  title = "Notepad",
}) {
  const [loader, setLoader] = useState(false);
  const { getNote, createNote } = useNoteAPI({
    orderId: orderId,
  });
  const note = useWatch({
    control: noteControl.control,
    name: "note",
  });
  const [debouncedNote] = useDebounce(note, 500);

  useEffect(() => {
    if (!!debouncedNote && orderId) {
      setLoader(true);
      createNote.mutate(
        { content: debouncedNote, order_id: orderId },
        {
          onSuccess: () => {
            setLoader(false);
          },
          onError: () => {
            setLoader(false);
          },
        }
      );
    }
  }, [debouncedNote]);

  useEffect(() => {
    if (!!getNote?.content && orderId) {
      noteControl.reset({ note: getNote?.content });
    }
  }, [getNote]);

  return (
    <Popover
      id="note-menu"
      className={styles.menu}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      // TransitionComponent={Fade}
    >
      <div className={styles.header}>
        <div className={styles.content}>
          <NotePadHeaderIcon />
          <div>
            <p className={styles.title}>{title}</p>
          </div>
          {loader && <CircularProgress size={15} />}
        </div>
        <CloseIcon onClick={onClose} className={styles.close} />
      </div>
      <div className={styles.body}>
        <Controller
          name="note"
          control={noteControl.control}
          render={({ field }) => (
            <TextareaAutosize
              {...field}
              placeholder="Enter a description..."
              className={styles.input}
            />
          )}
        />
      </div>
    </Popover>
  );
}
