import React, { forwardRef, useEffect } from "react";
import { Button, CircularProgress, Dialog } from "@mui/material";
import styles from "./style.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { UploadIcon } from "../../../../assets/icons";
import ImagePreview from "../ImagesPreview";
import useChats from "../../../../hooks/api/useChatsAPI";
import toast from "react-hot-toast";
import ChatTextArea from "../Tool/ChatTextArea";

const ChatFileUpload = forwardRef(
  (
    {
      open,
      setOpen,
      handleRemoveFile,
      setMultipleFiles,
      chatRefetch,
      messageRefetch,
      payload,
      clearAudio,
      setMessage,
      message,
      isLoading,
      handleScrollDown,
      multipleFiles,
      onMultipleFileAdd,
      setIsLoading,
      handleDrop,
      handleDragEnter,
      handleDragLeave,
    },
    ref
  ) => {
    const audio = new Audio("/sound/bubbleSound.mp3");

    const handleClose = () => {
      setOpen(false);
      setMultipleFiles([]);
    };

    const { sendMessage, sendMessageLoading } = useChats();

    const handleSendMessage = () => {
      sendMessage(payload, {
        onSuccess: () => {
          audio.play();
          chatRefetch();
          clearAudio();
          messageRefetch();
          handleClose();
          setMessage("");
          setTimeout(() => {
            handleScrollDown();
          }, 1500);
        },
        onError: (err) => {
          if (err?.data?.message) {
            toast.error(err?.data?.message);
          } else {
            toast.error("Error in sending Message!");
          }
        },
      });
    };

    useEffect(() => {
      if (open) {
        const dropArea = ref?.current;

        if (dropArea) {
          dropArea.addEventListener("dragenter", handleDragEnter);
          dropArea.addEventListener("dragleave", handleDragLeave);
          dropArea.addEventListener("dragover", (e) => e.preventDefault());
          dropArea.addEventListener("drop", handleDrop);
        }

        return () => {
          if (dropArea) {
            dropArea.removeEventListener("dragenter", handleDragEnter);
            dropArea.removeEventListener("dragleave", handleDragLeave);
            dropArea.removeEventListener("drop", handleDrop);
          }
        };
      }
    }, [handleDrop, handleDragLeave, handleDragEnter]);

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "8px",
          },
        }}
        ref={ref}
      >
        <div className={styles.box}>
          <CloseIcon onClick={handleClose} className={styles.close} />
          <div className={styles.header}>
            <div className={styles.add_user}>
              <UploadIcon />
            </div>
            <div className={styles.content}>
              <h3>Upload</h3>
              <p>{multipleFiles?.length} Images uploaded</p>
            </div>
          </div>
          <div className={styles.body}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <div className={styles.previews}>
                  {multipleFiles?.map((file) => (
                    <ImagePreview file={file} onRemove={handleRemoveFile} />
                  ))}
                </div>
                <ChatTextArea
                  onMultipleFileAdd={onMultipleFileAdd}
                  setMessage={setMessage}
                  message={message}
                  isUpload
                  handleSendMessage={handleSendMessage}
                  setIsLoading={setIsLoading}
                  sendMessageLoading={sendMessageLoading}
                  invalidRecord
                />
              </>
            )}
          </div>
          <div className={styles.footer}>
            <div className={styles.btn_group}>
              <Button className={styles.cancel_button} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                disabled={sendMessageLoading}
                className={styles.save_button}
                onClick={handleSendMessage}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
);

export default ChatFileUpload;
