import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { telegramApi } from "../../utils/telegramApi";

const useChats = (props) => {
  const {
    data,
    fetchNextPage: chatFetchNextPage,
    hasNextPage: chatHasNextPage,
    isLoading,
    isFetching: chatIsFetching,
    isError,
    refetch,
  } = useInfiniteQuery(
    ["GET_CHATS"],
    ({ pageParam = { limit: 10, offset: 0 } }) => {
      return telegramApi.get("telegram/chats", {
        params: {
          ...pageParam,
        },
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const limit = 10;
        const offset = allPages?.length * 10;

        if (!lastPage?.payload?.chats?.length) return false;

        return { limit, offset };
      },
      enabled: props?.access,
      refetchOnWindowFocus: false,
      onError: props?.onChatError,
      staleTime: 600000,
    }
  );

  const { data: notification } = useQuery(
    ["GET_CHAT_NOTIFICATIONS"],
    ({ pageParam = { limit: 10, offset: 0 } }) =>
      telegramApi.get(`telegram/chats`, {
        params: {
          ...pageParam,
        },
      }),
    {
      refetchInterval: props?.access ? 3000 : false,
      enabled: props?.access,
      refetchOnWindowFocus: false,
      onSuccess: props?.onChatSuccess,
      onError: props?.onChatError,
    }
  );

  const { data: chat, refetch: chatRefetch } = useQuery(
    ["GET_CHAT", props?.id, props?.hash],
    () => telegramApi.get(`telegram/chat/${props?.id}/${props?.hash}`),
    {
      enabled: !!props?.id,
      refetchInterval: 3000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: telegramInfo, refetch: refetchTelegramInfo } = useQuery(
    ["GET_TELEGRAM_INFO"],
    () => telegramApi.get(`telegram/auth`),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000000,
    }
  );

  const {
    data: messages,
    fetchNextPage,
    hasNextPage,
    isLoading: messagesLoading,
    isFetching,
    refetch: messageRefetch,
  } = useInfiniteQuery(
    ["GET_MESSAGES", props?.id, props?.hash],
    ({ pageParam = { limit: 100, offset_id: 0 } }) => {
      return telegramApi.get(`telegram/messages/${props?.id}/${props?.hash}`, {
        params: {
          ...pageParam,
        },
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const limit = 100;
        const offset = allPages?.length * 10;

        if (!lastPage?.payload?.messages?.Messages?.length) return false;

        return { limit, offset_id: offset / 10 };
      },
      enabled: !!props?.id,
      refetchInterval: 2000,
      refetchOnWindowFocus: false,
      cacheTime: 600000,
    }
  );

  const { data: pinMessages } = useQuery(
    ["GET_PINNED_MESSAGES", props?.id, props?.hash],
    () =>
      telegramApi.get(`telegram/messages/${props?.id}/${props?.hash}`, {
        params: {
          pinned: true,
        },
      }),
    {
      enabled: !!props?.id,
      refetchOnWindowFocus: false,
    }
  );

  const agentAssign = useMutation((data) =>
    telegramApi.post("chat/assign", data)
  );

  const telegramLogout = useMutation((data) =>
    telegramApi.post("telegram/logout", data)
  );

  const pinMessage = useMutation((data) =>
    telegramApi.patch(`telegram/message/${data?.id}/${data?.hashId}`, data)
  );

  const uploadFile = useMutation((data) => telegramApi.post("upload", data));

  const { mutate: sendMessage, isLoading: sendMessageLoading } = useMutation(
    (data) =>
      telegramApi.post(
        `telegram/message/sent/${data?.id}/${data?.hashId}`,
        data
      )
  );

  const { data: contacts, isLoading: contactLoading } = useQuery(
    ["GET_CONTACT", props?.search],
    () =>
      telegramApi.get("telegram/contact", {
        params: {
          search: props?.search,
        },
      }),
    {
      enabled: Boolean(props?.search),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  return {
    data,
    contacts,
    contactLoading,
    isLoading,
    chat,
    notification,
    agentAssign,
    messages,
    fetchNextPage,
    hasNextPage,
    isFetching,
    chatIsFetching,
    refetch,
    chatRefetch,
    messageRefetch,
    isError,
    sendMessage,
    messagesLoading,
    sendMessageLoading,
    chatFetchNextPage,
    chatHasNextPage,
    uploadFile,
    pinMessage,
    pinMessages,
    telegramInfo,
    telegramLogout,
    refetchTelegramInfo,
  };
};

export default useChats;
