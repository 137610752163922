import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./style.module.scss";
import { PoliciesPencilIcon } from "../../../../../../../assets/icons";
import PoliciesModal from "../PiliciesModal";
import { useWatch } from "react-hook-form";

const PoliciesSection = ({ control }) => {
  const [open, setOpen] = useState(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(null);
  };

  const policies = useWatch({
    control,
    name: "policies",
  });

  return (
    <Box className={styles.infoCard}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
        borderBottom={"1px solid #e8e8e8"}
        paddingBottom={"12px"}
      >
        <Typography variant="h6" className={styles.title}>
          Policies
        </Typography>

        <IconButton className={styles.icon} onClick={handleOpenModal}>
          <PoliciesPencilIcon />
        </IconButton>
      </Box>

      <p
        className={styles.text}
        dangerouslySetInnerHTML={{
          __html: policies?.replace(/\n/g, "<br>") || "Please add policies.",
        }}
      />
      <PoliciesModal onClose={handleCloseModal} open={open} control={control} />
    </Box>
  );
};

export default PoliciesSection;
