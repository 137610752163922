import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Content from "../../../../components/Content";
import Wrapper from "../../../../components/Wrapper";
import styles from "./style.module.scss";
import usePaymentMethodAPI from "../../../../hooks/api/usePaymentMethodAPI";

const PaymentMethodCreate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { paymentMethod, createPaymentMethod, updatePaymentMethod } =
    usePaymentMethodAPI({
      paymentMethodId: id,
    });

  const { control, handleSubmit, setValue, watch } = useForm({
    values: { ...(paymentMethod?.paymentMethod || {}) },
  });

  const onClick = useCallback((vals) => {
    if (!!id) {
      updatePaymentMethod.mutate(
        {
          ...vals,
        },
        {
          onSuccess: () => {
            toast.success("Payment Method is edited successful!");
            navigate("../");
          },
          onError: () => {
            toast.error("Error in editing Payment Method!");
          },
        }
      );
    } else
      createPaymentMethod.mutate(
        {
          ...vals,
        },
        {
          onSuccess: () => {
            toast.success("Payment Method is added successful!");
            navigate("../");
          },
          onError: () => {
            toast.error("Error in adding Payment Method!");
          },
        }
      );
  }, []);

  return (
    <form onSubmit={handleSubmit(onClick)}>
      <Wrapper>
        <div>
          <Content
            title={!!id ? "Edit Payment Method" : "Add Payment Method"}
            height="80vh"
          >
            <Box width="100%" display="flex" gap="20px">
              <Box width="50%" display="flex" gap="12px" marginTop="24px">
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Name</p>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} required />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Content>
        </div>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            type="submit"
            variant="outlinedSecondary"
            disabled={createPaymentMethod.isLoading}
            style={{
              width: "120px",
            }}
          >
            {createPaymentMethod.isLoading ? (
              <CircularProgress size={22} />
            ) : !!id ? (
              "Save"
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Wrapper>
    </form>
  );
};

export default PaymentMethodCreate;
