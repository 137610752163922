import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Content from "../../../../components/Content";
import Wrapper from "../../../../components/Wrapper";
import styles from "./style.module.scss";
import useInsuranceAPI from "../../../../hooks/api/useInsuranceAPI";
import useUploadImage from "../../../../hooks/api/useUploadImage";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { makeCDN } from "../../../../utils/uploadImage";

const InsuranceCreate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { insurance, createInsurance, updateInsurance } = useInsuranceAPI({
    insuranceId: id,
  });
  const { uploadMutation, getImage } = useUploadImage();

  const { control, handleSubmit, setValue, watch } = useForm({
    values: { ...(insurance?.insurance || {}) },
  });

  const image = useWatch({
    control,
    name: "image",
  });
  const onClick = useCallback((vals) => {
    if (!!id) {
      updateInsurance.mutate(
        {
          ...vals,
        },
        {
          onSuccess: () => {
            toast.success("Insurance is edited successful!");
            navigate("../");
          },
          onError: () => {
            toast.error("Error in editing Insurance!");
          },
        }
      );
    } else
      createInsurance.mutate(
        {
          ...vals,
        },
        {
          onSuccess: () => {
            toast.success("Insurance is added successful!");
            navigate("../");
          },
          onError: () => {
            toast.error("Error in adding Insurance!");
          },
        }
      );
  }, []);

  const onFileAdd = async (e) => {
    const formData = new FormData();

    formData.append("file", e.target.files[0]);

    uploadMutation.mutate(e.target.files[0], {
      onSuccess: async (res) => {
        const resp = await getImage(res.$id);
        setValue("image", resp.href);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onClick)}>
      <Wrapper>
        <div>
          <Content
            title={!!id ? "Edit Insurance" : "Add Insurance"}
            height="80vh"
          >
            <Box width="100%" display="flex" gap="20px">
              <Box width="100%" display="flex" gap="12px" marginTop="24px">
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Name</p>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} required />
                    )}
                  />
                </Box>
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Image</p>
                  <label
                    htmlFor="imageUpload"
                    style={{
                      height: "243px",
                      border: "2px dashed #c1c1c1",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    {uploadMutation.isLoading ? (
                      <CircularProgress size={30} />
                    ) : image ? (
                      <img
                        src={makeCDN(image)}
                        width="100%"
                        height="100%"
                        alt="Upload"
                        style={{ objectFit: "cover" }}
                      />
                    ) : (
                      <AddAPhotoIcon />
                    )}

                    <input
                      type="file"
                      id="imageUpload"
                      onChange={onFileAdd}
                      style={{
                        display: "none",
                      }}
                    />
                  </label>
                </Box>
              </Box>
            </Box>
          </Content>
        </div>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            type="submit"
            variant="outlinedSecondary"
            disabled={createInsurance.isLoading}
            style={{
              width: "120px",
            }}
          >
            {createInsurance.isLoading ? (
              <CircularProgress size={22} />
            ) : !!id ? (
              "Save"
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Wrapper>
    </form>
  );
};

export default InsuranceCreate;
