import { useMutation, useQuery } from "react-query";
import { request } from "../../utils/api";

const usePaymentMethodAPI = (props) => {
  const { data, refetch, isLoading } = useQuery(
    ["GET_PAYMENT_METHOD", props?.page, props?.amount],
    () =>
      request.get("payment-method", {
        params: {
          page: props.page - 1,
          limit: props.amount,
        },
      }),
    { enabled: !!props?.enabled }
  );

  const { data: paymentMethod } = useQuery(
    ["GET_PAYMENT_METHOD", props?.paymentMethodId],
    () => request.get("payment-method/" + props?.paymentMethodId),
    {
      enabled: !!props?.paymentMethodId,
      onSuccess: props?.handleHotelSuccess,
    }
  );

  const createPaymentMethod = useMutation((data) =>
    request.post("payment-method", data)
  );

  const updatePaymentMethod = useMutation((data) =>
    request.put("payment-method/" + data.id, data)
  );

  const deletePaymentMethod = useMutation((id) =>
    request.delete("payment-method/" + id)
  );

  return {
    data,
    isLoading,
    refetch,
    paymentMethod,
    createPaymentMethod,
    updatePaymentMethod,
    deletePaymentMethod,
  };
};

export default usePaymentMethodAPI;
