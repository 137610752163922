import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import styles from "./style.module.scss";
import { Controller } from "react-hook-form";

const MinimumAgeSection = ({ control }) => {
  return (
    <Box className={styles.infoCard}>
      <Typography variant="h6" className={styles.title}>
        Minimum check-in age
      </Typography>

      <Controller
        name="max_age"
        key={"max_age"}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            className={styles.input}
            placeholder="Enter minimum check-in age"
          />
        )}
      />
    </Box>
  );
};

export default MinimumAgeSection;
