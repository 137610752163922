import {
  Avatar,
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { CloseIcon, GlassIcon } from "../../../../assets/icons";
import useChats from "../../../../hooks/api/useChatsAPI.js";
import { ChatSkeleton } from "../Skeleton/index.jsx";
import styles from "./style.module.scss";
import SearchResult from "../SearchResult/index.jsx";
import classNames from "classnames";
import { stringSingleAvatar } from "../../../../utils/index.js";

const ChatSidebar = ({
  data,
  isLoading,
  chatIsFetching,
  chatFetchNextPage,
  chatHasNextPage,
  setLeadFormOpen,
  setReply,
  setIsPinField,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState(null);
  const [debouncedSearch] = useDebounce(search, 500);

  const { contacts, contactLoading } = useChats({
    search: debouncedSearch,
  });

  const chats = useMemo(() => {
    if (data) {
      const groupedChats = {};

      data?.pages?.forEach((page) => {
        page?.payload?.chats?.forEach((item) => {
          const formattedDate = moment(item?.LastMessage?.Date * 1000).format(
            "MMMM DD, YYYY"
          );
          const time = moment(item?.LastMessage?.Date * 1000).format("HH:mm");

          const chatItem = {
            name:
              item.Chat.Title || `${item.Chat.LastName} ${item.Chat.FirstName}`,
            last_name: item.Chat.LastName || item.Chat.Title,
            first_name: item.Chat.FirstName || item.Chat.Title,
            platform: "telegram.com",
            time: time,
            unread: item?.Dialog?.UnreadCount,
            avatar: item?.Agent.Photo || "/chat_ava.jpg",
            assigned: item?.IsAssigned && item?.Agent?.LastName,
            deleted: item?.Chat?.Deleted,
            id: `${item?.Agent.Id}-${item?.Chat.ID}`,
            hash: item?.Chat.AccessHashStr || item?.Chat.ID,
            chat_id: item?.Chat.ID,
            last_message: item?.LastMessage?.Message?.replace(/\n/g, " "),
            assigned_agent: `${item?.Agent?.LastName} ${item?.Agent?.FirstName}`,
          };

          if (!groupedChats[formattedDate]) {
            groupedChats[formattedDate] = [];
          }

          groupedChats[formattedDate].push(chatItem);
        });
      });

      return Object.keys(groupedChats)
        .sort((a, b) =>
          moment(b, "MMMM DD, YYYY").diff(moment(a, "MMMM DD, YYYY"))
        )
        .map((date) => ({
          date: date,
          users: groupedChats[date],
        }));
    }

    return [];
  }, [data]);

  const formattedContacts = useMemo(() => {
    if (contacts?.payload?.response) {
      return contacts?.payload?.response?.map((item) => {
        return {
          name: `${item?.LastName} ${item?.FirstName}`,
          username: item?.Username,
          platform: "telegram.com",
          avatar: item?.Photo || "/chat_ava.jpg",
          id: item?.ID,
          hash: item?.AccessHashStr,
          last_message: item?.LastMessage?.Message?.replace(/\n/g, " ") || "",
          unread: item?.Dialog?.UnreadCount || 0,
          assigned_agent: item?.Agent
            ? `${item?.Agent.LastName} ${item?.Agent.FirstName}`
            : null,
          assigned: item?.IsAssigned,
          time: item?.LastMessage
            ? moment(item?.LastMessage.Date).format("hh:mm A")
            : null,
          chat_id: item?.Chat?.ID,
        };
      });
    }

    return [];
  }, [contacts]);

  const handleClearSearch = () => {
    setSearch("");
    navigate(`/chat`);
    setReply({});
  };

  const handleChatClick = (user) => {
    setLeadFormOpen(false);
    navigate(`/chat/${user.chat_id}/${user.hash}`);
    setReply({});
    setIsPinField(false);
  };

  const groups =
    data?.pages?.flatMap((page) => page?.payload?.chats || []) || [];

  return (
    <div id="chat_sidebar" className={styles.sidebar}>
      <div className={styles.search}>
        <TextField
          style={{
            background: "#fff",
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {contactLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <GlassIcon />
                )}
              </InputAdornment>
            ),
            endAdornment: formattedContacts?.length ? (
              <InputAdornment>
                <Box className={styles.close_icon} onClick={handleClearSearch}>
                  <CloseIcon />
                </Box>
              </InputAdornment>
            ) : null,
          }}
        />
      </div>

      {formattedContacts?.length ? (
        <SearchResult
          formattedContacts={formattedContacts}
          setLeadFormOpen={setLeadFormOpen}
        />
      ) : (
        <>
          {isLoading ? (
            <ChatSkeleton />
          ) : (
            <div id="scrollableDiv" style={{ overflow: "auto" }}>
              <InfiniteScroll
                dataLength={groups?.length}
                next={chatFetchNextPage}
                hasMore={chatHasNextPage}
                style={{ overflow: "hidden" }}
                scrollableTarget="scrollableDiv"
                loader={
                  chatIsFetching && (
                    <Box className={styles.loader}>
                      <CircularProgress />
                    </Box>
                  )
                }
              >
                {chats?.map((group) => (
                  <div key={group.date} className={styles.date_group}>
                    <div className={styles.date}>{group.date}</div>
                    <div className={styles.group_box}>
                      {group.users.map((user, userIndex) => {
                        const isActive = user.chat_id?.toString() === id;
                        return (
                          <Box
                            key={userIndex}
                            className={classNames(styles.user, {
                              [styles.active]: isActive,
                            })}
                            onClick={() => {
                              handleChatClick(user);
                            }}
                          >
                            <div className={styles.userInfo}>
                              <Avatar
                                style={{
                                  width: "40px",
                                  height: "40px",
                                }}
                                {...stringSingleAvatar(
                                  [user?.last_name || user?.first_name].join(
                                    " "
                                  )
                                )}
                              />
                              <div className={styles.details}>
                                <div className={styles.userName}>
                                  {user?.deleted
                                    ? "Deleted account"
                                    : user.name}
                                </div>
                                <div className={styles.platform}>
                                  {user.last_message}
                                </div>
                                {user?.status && (
                                  <div className={styles.status}>
                                    <span className={styles.status_text}>
                                      {user.status}
                                    </span>
                                  </div>
                                )}
                                {!user?.assigned && (
                                  <div className={styles.unassigned}>
                                    <span className={styles.dot} />
                                    <p className={styles.unassigned_text}>
                                      Unassigned
                                    </p>
                                  </div>
                                )}
                                {user?.assigned && (
                                  <div className={styles.assigned}>
                                    <span className={styles.dot} />
                                    <p className={styles.assigned_text}>
                                      {user?.assigned_agent}
                                    </p>
                                  </div>
                                )}
                              </div>
                              <div className={styles.unreadTime}>
                                <div className={styles.time}>{user.time}</div>
                                {user.unread ? (
                                  <div className={styles.unread}>
                                    {user.unread}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </Box>
                        );
                      })}
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ChatSidebar;
