import { useMutation, useQuery } from "react-query";
import { request } from "../../utils/api";

const useInsuranceAPI = (props) => {
  const { data, refetch, isLoading } = useQuery(
    ["GET_INSURANCE", props?.page, props?.amount],
    () =>
      request.get("insurance", {
        params: {
          page: props.page - 1,
          limit: props.amount,
        },
      }),
    { enabled: !!props?.enabled }
  );

  const { data: insurance } = useQuery(
    ["GET_INSURANCE", props?.insuranceId],
    () => request.get("insurance/" + props?.insuranceId),
    {
      enabled: !!props?.insuranceId,
      onSuccess: props?.handleHotelSuccess,
    }
  );

  const createInsurance = useMutation((data) =>
    request.post("insurance", data)
  );

  const updateInsurance = useMutation((data) =>
    request.put("insurance/" + data.id, data)
  );

  const deleteInsurance = useMutation((id) =>
    request.delete("insurance/" + id)
  );

  return {
    data,
    isLoading,
    refetch,
    insurance,
    createInsurance,
    updateInsurance,
    deleteInsurance,
  };
};

export default useInsuranceAPI;
