import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import {
  DropdownUsersIcon,
  DropdownUsersMinusIcon,
  DropdownUsersPlusIcon,
} from "../../../../../../../assets/icons";
import styles from "./style.module.scss";
import { useWatch } from "react-hook-form";

const PassengerDropdown = ({
  placeholder,
  labelOptions = ["Adults", "Children", "Infant"],
  options,
  setValue,
  control,
}) => {
  const adult = useWatch({
    control,
    name: "adult",
  });
  const children = useWatch({
    control,
    name: "children",
  });
  const infant = useWatch({
    control,
    name: "infant",
  });
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState([adult, children, infant]);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleValueChange = (index, delta) => {
    setValues((prev) => {
      const next = prev.map((value, i) =>
        i === index ? Math.max(0, value + delta) : value
      );

      setValue("adult", next[0]);
      setValue("children", next[1]);
      setValue("infant", next[2]);

      return next;
    });
  };

  const label = values.map((val, i) => `${val}-${labelOptions[i]}`).join(", ");

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box position="relative" ref={dropdownRef}>
      <Box className={styles.dropdown} onClick={toggleDropdown}>
        <DropdownUsersIcon />
        {label || placeholder || "Select"}
      </Box>

      {isOpen && (
        <Box className={styles.menu}>
          {options.map((option, index) => (
            <Box key={option} className={styles.option}>
              <Typography>{option}</Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <IconButton
                  className={styles.icon}
                  onClick={() => handleValueChange(index, -1)}
                >
                  <DropdownUsersMinusIcon />
                </IconButton>
                <Typography minWidth="32px" textAlign="center">
                  {values[index]}
                </Typography>
                <IconButton
                  className={styles.icon}
                  onClick={() => handleValueChange(index, 1)}
                >
                  <DropdownUsersPlusIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default PassengerDropdown;
