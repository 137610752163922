import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedHotels: [],
};

const hotelsSlice = createSlice({
  name: "hotels",
  initialState,
  reducers: {
    addSelectedHotel: (state, action) => {
      const newHotel = action.payload;
      const existingHotelIndex = state.selectedHotels.findIndex(
        (hotel) => hotel.unique_id === newHotel.unique_id
      );

      if (existingHotelIndex !== -1) {
        state.selectedHotels[existingHotelIndex] = newHotel;
      } else {
        state.selectedHotels.push(newHotel);
      }
    },
    setHotels: (state, { payload }) => {
      state.selectedHotels = payload;
    },
    removeSelectedHotel: (state, action) => {
      const hotelId = action.payload;
      state.selectedHotels = state.selectedHotels.filter(
        (hotel) => hotel.unique_id !== hotelId
      );
    },
    clearSelectedHotels: (state) => {
      state.selectedHotels = [];
    },
  },
});

export const {
  addSelectedHotel,
  removeSelectedHotel,
  clearSelectedHotels,
  setHotels,
} = hotelsSlice.actions;
export default hotelsSlice.reducer;
