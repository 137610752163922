import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { Box, Rating, Typography } from "@mui/material";
import React from "react";
import { DateRangePicker } from "rsuite";
import { DropdownCalendarIcon } from "../../../../../../../assets/icons";
import PassengerDropdown from "../PessangerDropdown";
import RoomDropdown from "../RoomDropdown";
import styles from "./style.module.scss";
import { useWatch } from "react-hook-form";
import moment from "moment";
import classNames from "classnames";

const HotelInfoCard = ({ control, setValue }) => {
  const passengerOptions = [
    "Adult",
    "Children (2-12 years)",
    "Infant (0-2 years)",
  ];

  const handlePassengerApply = (values) => {
    alert(`Selected Passengers: ${values.join(", ")}`);
  };

  const country = useWatch({
    control,
    name: "country",
  });

  const region = useWatch({
    control,
    name: "region",
  });

  const address = useWatch({
    control,
    name: "address",
  });

  const watchDates = useWatch({ control, name: ["check_in", "check_out"] });

  return (
    <Box className={styles.infoCard} id="hotel">
      <Typography variant="h6" className={styles.title}>
        Hotel info
      </Typography>

      <Box className={styles.row}>
        <Box className={styles.column}>
          <Typography variant="subtitle2" className={styles.label}>
            Country
          </Typography>
          <Typography variant="body2" className={styles.value}>
            {`${country || ""} ${region || ""}`}
          </Typography>
        </Box>
        <Box className={styles.column}>
          <Typography variant="subtitle2" className={styles.label}>
            City
          </Typography>
          <Typography variant="body2" className={styles.value}>
            {region}
          </Typography>
        </Box>
      </Box>

      <Box className={styles.row}>
        <Box className={styles.column}>
          <Typography variant="subtitle2" className={styles.label}>
            Address
          </Typography>
          <Typography variant="body2" className={styles.value}>
            {address}
          </Typography>
        </Box>
        <Box className={styles.column}>
          <Typography variant="subtitle2" className={styles.label}>
            Hotel rating
          </Typography>
          <Rating name="rating" value={5} readOnly />
        </Box>
      </Box>

      <DateRangePicker
        className={classNames(styles.range, {
          [styles.right]: watchDates[0],
        })}
        format="MM/dd/yyyy HH:mm"
        caretAs={DropdownCalendarIcon}
        ranges={[]}
        value={
          watchDates[0] && watchDates[1]
            ? [
                new Date(moment.utc(watchDates[0]).format("YYYY-MM-DD HH:mm")),
                new Date(moment.utc(watchDates[1]).format("YYYY-MM-DD HH:mm")),
              ]
            : []
        }
        placeholder="Select dates"
        onChange={(value) => {
          setValue(
            "check_in",
            value?.[0]
              ? moment(value?.[0]).format("YYYY-MM-DD HH:mm")
              : undefined
          );
          setValue(
            "check_out",
            value?.[1]
              ? moment(value?.[1]).format("YYYY-MM-DD HH:mm")
              : undefined
          );
        }}
        placement="auto"
        preventOverflow
        cleanable
      />
      <PassengerDropdown
        icon={PeopleAltIcon}
        placeholder="Select passengers"
        onApply={handlePassengerApply}
        control={control}
        setValue={setValue}
        options={passengerOptions}
      />
      <RoomDropdown control={control} setValue={setValue} />
    </Box>
  );
};

export default HotelInfoCard;
