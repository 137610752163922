import { useMemo } from "react";
import Tabs, { tabStatuses } from "../../../components/Tabs";

import styles from "./style.module.scss";
import { Outlet, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import useOrder from "../../../hooks/useOrder";

export const orderSections = {
  LEAD: "lead",
  FLIGHTS: "flights",
  PASSENGERS: "passengers",
  PAYMENTS: "payments",
  HOTELS: "hotels",
};

const _tabs = [
  {
    id: orderSections.LEAD,
    label: "Lead Information",
    status: tabStatuses.EMPTY,
  },
  {
    id: orderSections.FLIGHTS,
    label: "Flight Information",
    status: tabStatuses.EMPTY,
  },
  {
    id: orderSections.PASSENGERS,
    label: "Passengers Information",
    status: tabStatuses.EMPTY,
  },
  {
    id: orderSections.HOTELS,
    label: "Hotel information",
    status: tabStatuses.EMPTY,
  },
  {
    id: orderSections.PAYMENTS,
    label: "Payment Information",
    status: tabStatuses.EMPTY,
  },
];

const CreateOrder = () => {
  const { pathname } = useLocation();
  const {
    lead,
    flights,
    passengers,
    payments,
    hotels,
    orderId,
    isConfirmed,
    isCanceled,
    params,
    addFlight,
    deleteFlight,
    changeFlight,
    setPassengers,
    changePassengerType,
    changePassengerPrice,
    changePaymentStatus,
    switchPaymentVals,
    handleCreateOrder,
    changeWeightUnit,
    changeWeightValue,
    addSegmentParams,
    addLeadInformations,
    noteControl,
  } = useOrder();

  const tabs = useMemo(() => {
    const paths = pathname.split("/");
    const vals = {
      lead,
      flights,
      passengers,
      payments,
      hotels,
    };

    const mutatedTabs = _tabs.map((tab) => ({
      ...tab,
      status: paths.includes(tab.id)
        ? tabStatuses.ACTIVE
        : tab.id === orderSections.PAYMENTS
        ? vals[tab.id]?.total_price > 0
          ? tabStatuses.DONE
          : tabStatuses.EMPTY
        : tab.id === orderSections.LEAD
        ? !!vals[tab.id].lead?.first_name
          ? tabStatuses.DONE
          : tabStatuses.EMPTY
        : tab.id === orderSections.HOTELS
        ? vals[tab.id]?.length > 0
          ? tabStatuses.DONE
          : tabStatuses.EMPTY
        : vals[tab.id]?.length > 0
        ? tabStatuses.DONE
        : tabStatuses.EMPTY,
    }));

    return mutatedTabs;
  }, [pathname, lead, flights, passengers, payments, hotels]);
  return (
    <div className={styles.container}>
      <Tabs tabs={tabs} />
      <Box className={styles.outlet}>
        <Outlet
          context={{
            lead,
            flights,
            passengers,
            payments,
            orderId,
            hotels,
            isConfirmed,
            isCanceled,
            params,
            addFlight,
            deleteFlight,
            changeFlight,
            setPassengers,
            changePassengerType,
            changePassengerPrice,
            changePaymentStatus,
            changeWeightUnit,
            changeWeightValue,
            switchPaymentVals,
            handleCreateOrder,
            addSegmentParams,
            addLeadInformations,
            noteControl,
          }}
        />
      </Box>
    </div>
  );
};

export default CreateOrder;
