import Orders from "../pages/Orders";
import CreateOrder, { orderSections } from "../pages/Orders/Create";
import Flights from "../pages/Orders/Create/Sections/Flights";
import Passengers from "../pages/Orders/Create/Sections/Passengers";
import Payments from "../pages/Orders/Create/Sections/Payment";
import Website from "../pages/Website";
import Categories from "../pages/Website/Categories";
import CategoryCreate from "../pages/Website/Categories/Create";
import Directions from "../pages/Website/Directions";
import DirectionCreate from "../pages/Website/Directions/Create";
import Countries from "../pages/Website/Countries";
import CountryCreate from "../pages/Website/Countries/Create";
import Contents from "../pages/Website/Contents";
import ContentCreate from "../pages/Website/Contents/Create";
import FlightSearches from "../pages/Website/FlightSearches";
import FlightSearchCreate from "../pages/Website/FlightSearches/Create";
import Regions from "../pages/Website/Regions";
import RegionCreate from "../pages/Website/Regions/Create";
import Requests from "../pages/Website/Requests";
import Reviews from "../pages/Website/Reviews";
import Users from "../pages/Users";
import UserCreate from "../pages/Users/Create";

import { Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Lead from "../pages/Orders/Create/Sections/Lead";
import StaticData from "../pages/StaticData";
import Airports from "../pages/StaticData/Airports";
import AirportCreate from "../pages/StaticData/Airports/Create";
import Airlines from "../pages/StaticData/Airlines";
import AirlineCreate from "../pages/StaticData/Airlines/Create";
import ChatPage from "../pages/Chat";
import ChatField from "../pages/Chat/components/Field";
import SessionPage from "../pages/Chat/components/Session";
import Hotels from "../pages/StaticData/Hotel";
import HotelCreate from "../pages/StaticData/Hotel/Create";
import RoomTypeCreate from "../pages/StaticData/RoomType/Create";
import RoomOption from "../pages/StaticData/RoomOption/index copy";
import RoomOptionCreate from "../pages/StaticData/RoomOption/Create";
import RoomTypes from "../pages/StaticData/RoomType";
import Insurance from "../pages/StaticData/Insurance";
import InsuranceCreate from "../pages/StaticData/Insurance/Create";
import PaymentMethod from "../pages/StaticData/PaymentMethod";
import PaymentMethodCreate from "../pages/StaticData/PaymentMethod/Create";
import TicketMerchant from "../pages/StaticData/TicketMerchant";
import TicketMerchantCreate from "../pages/StaticData/TicketMerchant/Create";
import HotelSection from "../pages/Orders/Create/Sections/Hotels";
import HotelDetailsPage from "../pages/Orders/Create/Sections/Hotels/HotelDetailPage";

export const _chatRoutes = [
  {
    index: true,
    element: <Navigate to="/chat" />,
  },
  {
    path: "chat",
    element: <ChatPage />,
    children: [
      {
        path: ":id/:hashId",
        element: <ChatField />,
      },
    ],
  },
  {
    path: "session",
    element: <SessionPage />,
  },
  {
    path: "*",
    element: <Navigate to="/chat" />,
  },
];
export const _agentRoutes = [
  {
    index: true,
    element: <Navigate to="/orders" />,
  },
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "orders",
    children: [
      {
        index: true,
        element: <Orders />,
      },
      {
        path: "create",
        element: <CreateOrder />,
        children: [
          {
            path: orderSections.LEAD,
            element: <Lead />,
          },
          {
            path: orderSections.FLIGHTS,
            element: <Flights />,
          },
          {
            path: orderSections.PASSENGERS,
            element: <Passengers />,
          },
          {
            path: orderSections.HOTELS,
            children: [
              {
                index: true,
                element: <HotelSection />,
              },
              { path: ":id", element: <HotelDetailsPage /> },
            ],
          },
          {
            path: orderSections.PAYMENTS,
            element: <Payments />,
          },
          {
            path: "*",
            element: <Navigate to="/orders/create/lead" />,
          },
          {
            index: true,
            element: <Navigate to="/orders/create/lead" />,
          },
        ],
      },
      {
        path: ":orderId",
        element: <CreateOrder />,
        children: [
          {
            path: orderSections.LEAD,
            element: <Lead />,
          },
          {
            path: orderSections.FLIGHTS,
            element: <Flights />,
          },
          {
            path: orderSections.PASSENGERS,
            element: <Passengers />,
          },
          {
            path: orderSections.PAYMENTS,
            element: <Payments />,
          },
          {
            path: orderSections.HOTELS,
            children: [
              {
                index: true,
                element: <HotelSection />,
              },
              { path: ":id", element: <HotelDetailsPage /> },
            ],
          },
          // {
          //   path: "*",
          //   element: <Navigate to="./lead" />,
          // },
          {
            index: true,
            element: <Navigate to="./lead" />,
          },
        ],
      },
    ],
  },
  ..._chatRoutes,
  {
    path: "*",
    element: <Navigate to="/orders" />,
  },
];
export const _editorRoutes = [
  {
    index: true,
    element: <Navigate to="/website" />,
  },
  {
    path: "website",
    element: <Website />,
    children: [
      {
        path: "reviews",
        children: [
          {
            index: true,
            element: <Reviews />,
          },
        ],
      },
      {
        path: "requests",
        children: [
          {
            index: true,
            element: <Requests />,
          },
        ],
      },
      {
        path: "directions",
        children: [
          {
            index: true,
            element: <Directions />,
          },
          {
            path: "create",
            element: <DirectionCreate />,
          },
          {
            path: ":id",
            element: <DirectionCreate />,
          },
          {
            path: "*",
            element: <Navigate to="/directions" />,
          },
        ],
      },
      {
        path: "contents",
        children: [
          {
            index: true,
            element: <Contents />,
          },
          {
            path: "create",
            element: <ContentCreate />,
          },
          {
            path: ":id",
            element: <ContentCreate />,
          },
          {
            path: "*",
            element: <Navigate to="/contents" />,
          },
        ],
      },
      {
        path: "categories",
        children: [
          {
            index: true,
            element: <Categories />,
          },
          {
            path: "create",
            element: <CategoryCreate />,
          },
          {
            path: ":id",
            element: <CategoryCreate />,
          },
          {
            path: "*",
            element: <Navigate to="/categories" />,
          },
        ],
      },
      {
        path: "flight-searches",
        children: [
          {
            index: true,
            element: <FlightSearches />,
          },
          {
            path: "create",
            element: <FlightSearchCreate />,
          },
          {
            path: ":id",
            element: <FlightSearchCreate />,
          },
          {
            path: "*",
            element: <Navigate to="/contents" />,
          },
        ],
      },
      {
        path: "regions",
        children: [
          {
            index: true,
            element: <Regions />,
          },
          {
            path: "create",
            element: <RegionCreate />,
          },
          {
            path: ":id",
            element: <RegionCreate />,
          },
          {
            path: "*",
            element: <Navigate to="/regions" />,
          },
        ],
      },
      {
        path: "countries",
        children: [
          {
            index: true,
            element: <Countries />,
          },
          {
            path: "create",
            element: <CountryCreate />,
          },
          {
            path: ":id",
            element: <CountryCreate />,
          },
          {
            path: "*",
            element: <Navigate to="/countries" />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/website" />,
  },
];
export const _staticRoutes = [
  {
    index: true,
    element: <Navigate to="/static-data" />,
  },
  {
    path: "static-data",
    element: <StaticData />,
    children: [
      {
        path: "airports",
        children: [
          {
            index: true,
            element: <Airports />,
          },
          {
            path: "create",
            element: <AirportCreate />,
          },
          {
            path: ":id",
            element: <AirportCreate />,
          },
          {
            path: "*",
            element: <Navigate to="/airports" />,
          },
        ],
      },
      {
        path: "airlines",
        children: [
          {
            index: true,
            element: <Airlines />,
          },
          {
            path: "create",
            element: <AirlineCreate />,
          },
          {
            path: ":id",
            element: <AirlineCreate />,
          },
          {
            path: "*",
            element: <Navigate to="/airports" />,
          },
        ],
      },
      {
        path: "hotels",
        children: [
          {
            index: true,
            element: <Hotels />,
          },
          {
            path: "create",
            element: <HotelCreate />,
          },
          {
            path: ":id",
            element: <HotelCreate />,
          },
          {
            path: "*",
            element: <Navigate to="/hotels" />,
          },
        ],
      },
      {
        path: "hotels/room-type",
        children: [
          {
            index: true,
            element: <RoomTypes />,
          },
          {
            path: "create",
            element: <RoomTypeCreate />,
          },
          {
            path: ":id",
            element: <RoomTypeCreate />,
          },
          {
            path: "*",
            element: <Navigate to="/room-type" />,
          },
        ],
      },
      {
        path: "hotels/room-option",
        children: [
          {
            index: true,
            element: <RoomOption />,
          },
          {
            path: "create",
            element: <RoomOptionCreate />,
          },
          {
            path: ":id",
            element: <RoomOptionCreate />,
          },
          {
            path: "*",
            element: <Navigate to="/room-option" />,
          },
        ],
      },
      {
        path: "insurance",
        children: [
          {
            index: true,
            element: <Insurance />,
          },
          {
            path: "create",
            element: <InsuranceCreate />,
          },
          {
            path: ":id",
            element: <InsuranceCreate />,
          },
          {
            path: "*",
            element: <Navigate to="/room-option" />,
          },
        ],
      },
      {
        path: "payment-method",
        children: [
          {
            index: true,
            element: <PaymentMethod />,
          },
          {
            path: "create",
            element: <PaymentMethodCreate />,
          },
          {
            path: ":id",
            element: <PaymentMethodCreate />,
          },
          {
            path: "*",
            element: <Navigate to="/room-option" />,
          },
        ],
      },
      {
        path: "ticket-merchant",
        children: [
          {
            index: true,
            element: <TicketMerchant />,
          },
          {
            path: "create",
            element: <TicketMerchantCreate />,
          },
          {
            path: ":id",
            element: <TicketMerchantCreate />,
          },
          {
            path: "*",
            element: <Navigate to="/room-option" />,
          },
        ],
      },
    ],
  },

  {
    path: "*",
    element: <Navigate to="/static-data" />,
  },
];

export const _adminRoutes = [
  {
    path: "users",
    children: [
      {
        index: true,
        element: <Users />,
      },
      {
        path: "create",
        element: <UserCreate />,
      },
      {
        path: ":id",
        element: <UserCreate />,
      },
    ],
  },
  ..._agentRoutes,
  ..._editorRoutes,
  ..._staticRoutes,
  ..._chatRoutes,
];
