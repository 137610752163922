import { useMutation, useQuery } from "react-query";
import { request } from "../../utils/api";

const useRoomOptionAPI = (props) => {
  const { data, refetch, isLoading } = useQuery(
    ["GET_ROOM_OPTION", props?.page, props?.amount, props?.room_type_id],
    () =>
      request.get("room-option", {
        params: {
          page: props?.page > 0 ? props?.page - 1 : undefined,
          limit: props?.amount,
          room_type_id: props?.room_type_id || undefined,
        },
      }),
    { enabled: !!props?.enabled }
  );

  const { data: roomOption } = useQuery(
    ["GET_ROOM_OPTION", props?.roomOptionId],
    () => request.get("room-option/" + props?.roomOptionId),
    {
      enabled: !!props?.roomOptionId,
    }
  );

  const createRoomOption = useMutation((data) =>
    request.post("room-option", data)
  );

  const updateRoomOption = useMutation((data) =>
    request.put("room-option/" + data.id, data)
  );

  const deleteRoomOption = useMutation((id) =>
    request.delete("room-option/" + id)
  );

  return {
    data,
    isLoading,
    refetch,
    roomOption,
    createRoomOption,
    updateRoomOption,
    deleteRoomOption,
  };
};

export default useRoomOptionAPI;
