import { request } from "./api";

export const promiseHotels = async (query) => {
  const hotels = await request.get("hotel", {
    params: {
      term: query,
      limit: 20,
    },
  });
  if (hotels?.hotels?.rows?.length > 0)
    return hotels?.hotels?.rows?.map((hotel) => ({
      value: hotel?.id,
      label: hotel?.name,
    }));

  if (query?.length < 3) return [];

  return hotels?.hotels?.rows;
};
