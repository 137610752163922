import { Box, IconButton, Typography } from "@mui/material";
import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  DropdownRoomIcon,
  DropdownUsersMinusIcon,
  DropdownUsersPlusIcon,
} from "../../../../../../../assets/icons";

import { useFieldArray } from "react-hook-form";
import styles from "./style.module.scss";
import useRoomTypeAPI from "../../../../../../../hooks/api/useRoomTypeAPI";

const RoomDropdown = ({ control }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { data } = useRoomTypeAPI({
    enabled: true,
  });

  const roomTypes = useMemo(() => {
    return (
      data?.roomTypes?.rows?.map((item) => ({
        label: item?.name,
        value: item?.id,
      })) || []
    );
  }, [data]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "rooms",
  });

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const getQuantity = (roomTypeId) => {
    return fields.filter((item) => item.room_type_id === roomTypeId).length;
  };

  const handleValueChange = (option, delta) => {
    const roomTypeId = option.value;

    if (delta > 0) {
      append({ room_type_id: roomTypeId, name: option.label });
    } else {
      const indexToRemove = fields
        .map((field, i) => ({ ...field, index: i }))
        .filter((fld) => fld.room_type_id === roomTypeId)
        .map((fld) => fld.index)
        .pop();

      if (indexToRemove !== undefined) {
        remove(indexToRemove);
      }
    }
  };

  const label = useMemo(() => {
    if (!fields.length) return "Select rooms";

    const counts = fields.reduce((acc, item) => {
      if (!acc[item.room_type_id]) acc[item.room_type_id] = 0;
      acc[item.room_type_id]++;
      return acc;
    }, {});

    const result = Object.entries(counts)
      .map(([roomTypeId, count]) => {
        const rt = roomTypes.find(
          (rt) => String(rt.value) === String(roomTypeId)
        );
        return rt
          ? `${count}-${rt.label}`
          : `${count}-RoomTypeID:${roomTypeId}`;
      })
      .join(", ");

    return result;
  }, [fields, roomTypes]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box position="relative" ref={dropdownRef}>
      <Box className={styles.dropdown} onClick={toggleDropdown}>
        <DropdownRoomIcon />
        {label}
      </Box>

      {isOpen && (
        <Box className={styles.menu}>
          {roomTypes.map((option) => {
            const quantity = getQuantity(option.value);

            return (
              <Box key={option.value} className={styles.option}>
                <Typography>{option.label}</Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <IconButton
                    className={styles.icon}
                    onClick={() => handleValueChange(option, -1)}
                  >
                    <DropdownUsersMinusIcon />
                  </IconButton>
                  <Typography minWidth={"32px"} textAlign={"center"}>
                    {quantity}
                  </Typography>
                  <IconButton
                    className={styles.icon}
                    onClick={() => handleValueChange(option, 1)}
                  >
                    <DropdownUsersPlusIcon />
                  </IconButton>
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default RoomDropdown;
