import { useMutation, useQuery } from "react-query";
import { request } from "../../utils/api";

const useHotelsAPI = (props) => {
  const { data, refetch, isLoading } = useQuery(
    ["GET_HOTELS", props?.page, props?.amount, props?.search],
    () =>
      request.get("hotel", {
        params: {
          page: props?.page - 1 || undefined,
          limit: props?.amount,
          term: props?.search,
        },
      }),
    { enabled: !!props?.enabled }
  );

  const { data: hotel } = useQuery(
    ["GET_HOTELS", props?.hotelId],
    () => request.get("hotel/" + props?.hotelId),
    {
      enabled: !!props?.hotelId,
      onSuccess: props?.handleHotelSuccess,
    }
  );

  const createHotel = useMutation((data) => request.post("hotel", data));

  const updateHotel = useMutation((data) =>
    request.put("hotel/" + data.id, data)
  );

  const deleteHotel = useMutation((id) => request.delete("hotel/" + id));

  return {
    data,
    isLoading,
    refetch,
    hotel,
    createHotel,
    updateHotel,
    deleteHotel,
  };
};

export default useHotelsAPI;
