import { Box, Modal } from "@mui/material";
import ImageGallery from "react-image-gallery";
import styles from "./styles.module.scss";
import "react-image-gallery/styles/scss/image-gallery.scss";
import { useWatch } from "react-hook-form";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "90vh",
  px: 4,
  pb: 3,
  borderRadius: "10px",
};

const HotelGalleryPopup = ({ isOpen, close, control }) => {
  const images = useWatch({
    control,
    name: "images",
  });
  const imgs = images?.map((item) => {
    return {
      original: item,
      thumbnail: item,
    };
  });
  return (
    <Modal
      open={isOpen}
      onClose={close}
      className={styles.order_popup}
      id="hotel-gallery-pop"
    >
      <Box className={styles.card} style={modalStyle}>
        {images && (
          <ImageGallery
            items={imgs}
            showThumbnails={true}
            showPlayButton={false}
            showFullscreenButton={false}
            useBrowserFullscreen
            additionalClass={styles.customGallery}
          />
        )}
      </Box>
    </Modal>
  );
};

export default HotelGalleryPopup;
