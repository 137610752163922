import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Content from "../../../../components/Content";
import Wrapper from "../../../../components/Wrapper";
import styles from "./style.module.scss";
import useTicketMerchantAPI from "../../../../hooks/api/useTicketMerchantAPI";

const TicketMerchantCreate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { ticketMerchant, createTicketMerchant, updateTicketMerchant } =
    useTicketMerchantAPI({
      ticketMerchantId: id,
    });

  const { control, handleSubmit } = useForm({
    values: { ...(ticketMerchant?.ticketMerchant || {}) },
  });

  const onClick = useCallback((vals) => {
    if (!!id) {
      updateTicketMerchant.mutate(
        {
          ...vals,
        },
        {
          onSuccess: () => {
            toast.success("Ticket Merchant is edited successful!");
            navigate("../");
          },
          onError: () => {
            toast.error("Error in editing Ticket Merchant!");
          },
        }
      );
    } else
      createTicketMerchant.mutate(
        {
          ...vals,
        },
        {
          onSuccess: () => {
            toast.success("Ticket Merchant is added successful!");
            navigate("../");
          },
          onError: () => {
            toast.error("Error in adding Ticket Merchant!");
          },
        }
      );
  }, []);

  return (
    <form onSubmit={handleSubmit(onClick)}>
      <Wrapper>
        <div>
          <Content
            title={!!id ? "Edit Ticket Merchant" : "Add Ticket Merchant"}
            height="80vh"
          >
            <Box width="100%" display="flex" gap="20px">
              <Box width="100%" display="flex" gap="12px" marginTop="24px">
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Name</p>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} required />
                    )}
                  />
                </Box>
              </Box>
              <Box width="100%" display="flex" gap="12px" marginTop="24px">
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>IATA Code</p>
                  <Controller
                    name="iata_code"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} required />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Content>
        </div>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            type="submit"
            variant="outlinedSecondary"
            disabled={createTicketMerchant.isLoading}
            style={{
              width: "120px",
            }}
          >
            {createTicketMerchant.isLoading ? (
              <CircularProgress size={22} />
            ) : !!id ? (
              "Save"
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Wrapper>
    </form>
  );
};

export default TicketMerchantCreate;
