import React, { useState } from "react";
import { Box, Typography, Button, Link } from "@mui/material";
import styles from "./style.module.scss";
import HotelInfoCard from "../components/HotelInfoCard";
import HotelGallery from "../components/HotelGallery";
import RoomSection from "../components/RoomSection";
import PoliciesSection from "../components/Policies";
import MinimumAgeSection from "../components/MinimumAgeSection/index copy";
import PriceSection from "../components/PriceSection/index copy";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { addSelectedHotel } from "../../../../../../redux/hotel/hotelSlice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HotelGalleryPopup from "../components/GalleryModal";

const HotelDetailsPage = () => {
  const { id, orderId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { selectedHotels } = useSelector((store) => store.hotels);
  const currentHotel = selectedHotels.find((item) => item.unique_id === id);

  const { control, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      hotelName: currentHotel?.label || "",
      address: currentHotel?.address || currentHotel?.data?.address,
      country: currentHotel?.country || currentHotel?.data?.country?.name,
      region: currentHotel?.region || currentHotel?.data?.region?.name,
      images: currentHotel?.images || currentHotel?.data?.images,
      rating: currentHotel?.rating || 5,
      adult: currentHotel?.adult || 1,
      children: currentHotel?.children || 2,
      infant: currentHotel?.infant || 0,
      ...currentHotel,
    },
  });

  const onSubmit = (formData) => {
    dispatch(
      addSelectedHotel({
        hotel_id: id,
        value: id,
        label: formData.hotelName,
        ...formData,
      })
    );
    if (orderId) {
      navigate(`/orders/${orderId}/hotels`);
    } else {
      navigate("/orders/create/hotels");
    }
  };

  const hotelName = useWatch({
    control,
    name: "hotelName",
  });

  const address = useWatch({
    control,
    name: "address",
  });

  const rooms = useWatch({
    control,
    name: "rooms",
  });

  const max_age = useWatch({
    control,
    name: "max_age",
  });

  const net_price = useWatch({
    control,
    name: "net_price",
  });

  const grous_price = useWatch({
    control,
    name: "grous_price",
  });

  const watchDates = useWatch({ control, name: ["check_in", "check_out"] });

  const handleRedirect = () => {
    if (orderId) {
      navigate(`/orders/${orderId}/hotels`);
    } else {
      navigate("/orders/create/hotels");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.container}>
      <Box className={styles.header}>
        <Link className={styles.backButton} onClick={handleRedirect}>
          <ArrowBackIcon /> Back to search
        </Link>
        <Box className={styles.card}>
          <Box className={styles.titleSection}>
            <Typography variant="h4" className={styles.hotelName}>
              {hotelName}
            </Typography>
            <Typography variant="body1" className={styles.hotelAddress}>
              {address}
            </Typography>
          </Box>
          <Button
            type="submit"
            disabled={
              !rooms || !watchDates[0] || !max_age || !net_price || !grous_price
            }
            variant="contained"
            className={styles.addHotelButton}
          >
            Add hotel
          </Button>
        </Box>
      </Box>
      <Box className={styles.contentWrapper}>
        <Box className={styles.leftColumn}>
          <HotelInfoCard control={control} setValue={setValue} />
          <RoomSection control={control} setValue={setValue} />
          <PoliciesSection control={control} setValue={setValue} />
        </Box>
        <Box className={styles.rightColumn}>
          <HotelGallery control={control} setOpen={setOpen} />
          <MinimumAgeSection control={control} />
          <PriceSection control={control} />
        </Box>
      </Box>
      <HotelGalleryPopup
        isOpen={open}
        close={() => setOpen(false)}
        control={control}
      />
    </form>
  );
};

export default HotelDetailsPage;
